// Angular
import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { ActivatedRoute } from "@angular/router";
// Environment
import { environment } from "../../../environments/environment";
// Interfaces
import { IPlaybackActionState } from "../interfaces/PlaybackActionState";
import { ISelectableAutomatedTestCase } from "../interfaces/SelectableAutomatedTestCases";
import { IPlayableAutomatedTestCase } from "../interfaces/PlayableAutomatedTestCases";
import { IPlaybackAction } from "../interfaces/PlaybackAction";
// Constants
import {
  PlaybackActionStates,
  PlaybackStates,
} from "../../shared/constants/enums";
//Services
import { ApplicationsService } from "./applications.service";
// Third party
import { map } from "rxjs/operators";
import { BehaviorSubject, Observable, Subscription, Subject } from "rxjs";

const httpOptions = {
  headers: new HttpHeaders({
    "Content-Type": "application/json",
    SofyAuth: "67935C5B-A009-4DC8-9EC6-2F8E9D58E6DC",
  }),
};
@Injectable({
  providedIn: "root",
})
export class PlayBackService {
  isFilter: any = false;
  AutomatedTestCases$: BehaviorSubject<ISelectableAutomatedTestCase[]> =
    new BehaviorSubject<ISelectableAutomatedTestCase[]>(null);
  SelectedTestCasesToPlay$: BehaviorSubject<IPlayableAutomatedTestCase[]> =
    new BehaviorSubject<IPlayableAutomatedTestCase[]>(null);
  PlaybackStatus$: BehaviorSubject<number> = new BehaviorSubject<number>(
    PlaybackStates.IDLE
  );
  CurrentPlaybackComponents$: BehaviorSubject<IPlaybackAction[]> =
    new BehaviorSubject<IPlaybackAction[]>(null);
  CurrentPlayBackData$: BehaviorSubject<any> = new BehaviorSubject<any>({});
  CurrentPlaybackIndexStatus$: Subject<IPlaybackActionState> =
    new Subject<IPlaybackActionState>();
  PlaybackIndexStatuses: IPlaybackActionState[] = [];
  goToResults: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  firstPlayBackOnboardUser: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);
  clearFilter: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  searchPlayback$: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  filterPlayback$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);
  currentRecordingMode$: BehaviorSubject<string> = new BehaviorSubject<string>("Record a new Test");
  PlaybackCurrentIndex = -1;

  UpdateIndexStatusesSubscription: Subscription;
  OnPlaybackIndexEmit: Subscription;
  isAutomatedRunStarted: boolean = false;
  isManualRunStarted: boolean = false;
  refreshingList: boolean = false;
  authkey: any;
  userGUID: any;
  applicationGUID: any;

  constructor(
    private httpClient: HttpClient,
    private route: ActivatedRoute,  
    private applicationService: ApplicationsService
  ) {
    this.UpdateIndexStatusesSubscription =
      this.CurrentPlaybackComponents$.subscribe((data) => {
        if (data) {
          this.PlaybackIndexStatuses.forEach(
            (i) =>
              (data[i.index].playbackStatus = this.getPlaybackStatusEnum(
                i.status
              ))
          );
          this.PlaybackIndexStatuses = [];
        }
      });

    this.OnPlaybackIndexEmit = this.CurrentPlaybackIndexStatus$.subscribe(
      (data) => {
        const playbackComponents = this.CurrentPlaybackComponents$.getValue();
        if (playbackComponents && playbackComponents.length > 0) {
          playbackComponents[data.index].playbackStatus =
            this.getPlaybackStatusEnum(data.status);
          if (data.index + 1 < playbackComponents.length) {
            playbackComponents[data.index + 1].playbackStatus =
              this.getPlaybackStatusEnum("WAITING");
          }
        } else {
          this.PlaybackIndexStatuses.push(data);
        }
      }
    );

    this.applicationService.SelectedApplication.subscribe((app) => {
      if (app) {
        this.applicationGUID = app.ApplicationGUID;
      }
    });

    this.route.queryParams.subscribe((params) => {
      this.userGUID = params.UserGUID;
      this.applicationGUID = params.applicationGUID;
    });
  }

  // Get testcase tags
  getAutomatedTestCasetags(applicationId: any) {
    let URL = `/automation-microservice/test-case/${applicationId}/tags`;
    return this.httpClient.get(environment.baseUrl + `${URL}`, httpOptions);
  }
  getPlaybackStatusEnum(strStatus: string): any {
    switch (strStatus) {
      case "FAIL":
        return 2;
      case "PASS":
        return 1;
      case "WARNING":
        return 3;
      case "WAITING":
        return 0;
      case "UNSET":
        return 4;
    }
  }

  GetCurrentAutomatedTestCase(): IPlayableAutomatedTestCase {
    const playables = this.SelectedTestCasesToPlay$.getValue();
    if (playables) {
      const toPlay = playables.filter((i) => i.playing === true)[0];
      return toPlay;
    }
    return null;
  }

  GetAutomatedTestCasesByApplicationId(
    ApplicationId: number
  ): Observable<ISelectableAutomatedTestCase[]> {
    return this.httpClient
      .get<ISelectableAutomatedTestCase[]>(
        environment.baseUrl +
          `/api/plugin/GetScenariosByApplicationId?appId=${ApplicationId}`,
        httpOptions
      )
      .pipe(
        map((data) => {
          return data.map((item) => {
            return { ...item, selected: false };
          });
        })
      );
  }

  getAutomatedTestCaseList(
    priority: string = "",
    filter: string = "",
    email: string = "",
    fromDate: string = null,
    toDate: string = null,
    tags: string = "",
    pageNo: number = 0
  ): any {
    let URL = `/automation-microservice/test-cases/${this.applicationGUID}`;
    const queryParams = {
      pgNo: pageNo > 0 ? pageNo : undefined,
      hasTemplate: true,
      flt: filter || undefined,
      prio: priority || undefined,
      em: encodeURIComponent(email) || undefined,
      fd: fromDate || undefined,
      td: toDate || undefined,
      tags: tags || undefined,
    };

    const queryString = Object.entries(queryParams)
      .filter(([_, value]) => value !== undefined) // Exclude undefined values
      .map(([key, value]) => `${key}=${value}`)
      .join("&");

    if (queryString) {
      URL += `?${queryString}`;
    }

    return this.httpClient.get(environment.baseUrl + `${URL}`, httpOptions);
  }
  getTemplateDetail(): any {
    const URL = `${environment.baseUrl}/automation-microservice/templates/${this.applicationGUID}`;

    return this.httpClient.get(URL, httpOptions);
  }
  editActiveScenario(
    scenarioJson: string,
    scenarioGuid: string,
    TestDataIDs: string,
    applicationID: any,
    trainingScenarioId: number
  ) {
    return this.httpClient.put(
      environment.baseUrl +
        "/automation-microservice/test-case/" +
        trainingScenarioId,
      {
        scenarioJSON: scenarioJson,
        scenarioGUID: scenarioGuid,
        testDataIDs: TestDataIDs,
        applicationId: applicationID,
      },
      httpOptions
    );
  }
  getListOfTestData(appGUID: string) {
    return this.httpClient.get(
      environment.baseUrl +
        `/api/Applications/${appGUID}/GetListOfTestData?Type=ALL`
    );
  }

  getTestCase(scenarioGUID: string){

    return this.httpClient.get(
      environment.baseUrl  +
      `/automation-microservice/test-case/${scenarioGUID}/steps`
    );
  }

  getTemplate(scenarioGUID: string){

    return this.httpClient.get(
      environment.baseUrl  +
      `/automation-microservice/template/${scenarioGUID}/steps`
    );
  }


  SetSelectedAutomatedTestCaseForPlayback() {
    const allCases = this.AutomatedTestCases$.getValue();
    if (allCases) {
      let selectedCases = allCases.filter((i) => i.selected === true);
      selectedCases = selectedCases.sort((a, b) =>
        a.order > b.order ? 1 : -1
      );
      const playableCases = selectedCases.map((i) => {
        return {
          ...i,
          stopped: false,
          completed: false,
          playing: false,
          saving: false,
          uploading: false,
          downloading: false,
          downloadPercentage: 0,
        } as IPlayableAutomatedTestCase;
      });

      this.PlaybackCurrentIndex = 0;
      playableCases[this.PlaybackCurrentIndex].playing = true;
      this.SelectedTestCasesToPlay$.next(playableCases);
      this.PlaybackStatus$.next(PlaybackStates.START);
    } else {
      this.SelectedTestCasesToPlay$.next(null);
    }
  }

  getAuthorizationKey(userGuid: string): any {
    const URL = `/user-microservice/user/${userGuid}/subscription-auth`;
    return this.httpClient
      .get<any[]>(environment.baseUrl + URL, httpOptions)
      .pipe(map((res: any) => res.data))
      .subscribe((subscriptionAuthDetail: any) => {
        this.authkey = subscriptionAuthDetail?.SubscriptionAuth;
        // this.getAutomatedTestCaseList();
      });
  }

  getSelectedLength(): number {
    return this.AutomatedTestCases$.getValue().filter(
      (i) => i.selected === true
    ).length;
  }
  getSelectedLengthForPreview(): any {
    let manuallySelectedTestCase = this.AutomatedTestCases$.getValue()[0];
    manuallySelectedTestCase.selected = true;
    return manuallySelectedTestCase;
  }

  SetCurrentPlayableAutomatedTestCaseCompleted() {
    const playableCases = this.SelectedTestCasesToPlay$.getValue();
    if (playableCases) {
      const currentPlaying = playableCases.filter((i) => i.playing === true)[0];
      currentPlaying.playing = false;
      currentPlaying.completed = true;
      const remainingToPlay = playableCases.filter((i) => !i.completed);
      if (remainingToPlay.length > 0) {
        remainingToPlay[0].playing = true;
        this.PlaybackIndexStatuses = [];
        this.CurrentPlaybackComponents$.next(null);
        this.CurrentPlaybackIndexStatus$.next(null);

        this.PlaybackStatus$.next(PlaybackStates.COMPLETED);
      } else {
        const automatedCases = this.AutomatedTestCases$.getValue();
        automatedCases.forEach((i) => (i.selected = false));
        this.PlaybackCurrentIndex = -1;
        this.SelectedTestCasesToPlay$.next(null);
        this.CurrentPlaybackComponents$.next(null);
        this.CurrentPlaybackIndexStatus$.next(null);
      }
    }
  }

  stoppedPlayBack() {
    const playableCases = this.SelectedTestCasesToPlay$.getValue();
    if (playableCases) {
      const currentPlaying = playableCases.filter((i) => i.playing === true)[0];
      currentPlaying.playing = false;
      currentPlaying.completed = false;
      this.CurrentPlaybackComponents$.next(null);
      this.CurrentPlaybackIndexStatus$.next(null);
      this.PlaybackStatus$.next(PlaybackStates.IDLE);
    }
  }

  SetPlaying() {
    const playableCases = this.SelectedTestCasesToPlay$.getValue();
    if (playableCases) {
      const currentPlaying = playableCases.filter((i) => i.playing === true)[0];
      currentPlaying.uploading = false;
      currentPlaying.saving = false;
      currentPlaying.downloading = false;
      currentPlaying.downloadPercentage = 0;
      currentPlaying.stopped = false;
      currentPlaying.completed = false;
    }
    this.PlaybackStatus$.next(PlaybackStates.PLAYING);
  }

  SetCurrentPlayableAutomatedTestCaseUploading() {
    const playableCases = this.SelectedTestCasesToPlay$.getValue();
    if (playableCases) {
      const currentPlaying = playableCases.filter((i) => i.playing === true)[0];
      currentPlaying.uploading = true;
      this.PlaybackStatus$.next(PlaybackStates.UPLOADING);
    }
  }

  SetCurrentPlayableAutomatedTestCaseSaving() {
    const playableCases = this.SelectedTestCasesToPlay$.getValue();
    if (playableCases) {
      const currentPlaying = playableCases.filter((i) => i.playing === true)[0];
      currentPlaying.saving = true;
      this.PlaybackStatus$.next(PlaybackStates.SAVING);
    }
  }

  UnsetCurrentPlayableAutomatedTestCaseUploading() {
    const playableCases = this.SelectedTestCasesToPlay$.getValue();
    if (playableCases) {
      const currentPlaying = playableCases.filter((i) => i.playing === true)[0];
      currentPlaying.uploading = false;
    }
  }

  UnsetCurrentPlayableAutomatedTestCaseSaving() {
    const playableCases = this.SelectedTestCasesToPlay$.getValue();
    if (playableCases) {
      const currentPlaying = playableCases.filter((i) => i.playing === true)[0];
      currentPlaying.saving = false;
    }
  }

  SetCurrentPlayableAutomatedTestCaseDownloading() {
    const playableCases = this.SelectedTestCasesToPlay$.getValue();
    if (playableCases) {
      const currentPlaying = playableCases.filter((i) => i.playing === true)[0];
      currentPlaying.downloading = true;
      this.PlaybackStatus$.next(PlaybackStates.DOWNLOAD);
    }
  }

  UnetCurrentPlayableAutomatedTestCaseDownloading() {
    const playableCases = this.SelectedTestCasesToPlay$.getValue();
    if (playableCases) {
      const currentPlaying = playableCases.filter((i) => i.playing === true)[0];
      currentPlaying.downloading = false;
    }
  }

  SetCurrentPlayableAutomatedTestCaseDownloadPercent(percentage: number) {
    const playableCases = this.SelectedTestCasesToPlay$.getValue();
    if (playableCases) {
      const currentPlaying = playableCases.filter((i) => i.playing === true)[0];
      currentPlaying.downloadPercentage = percentage;
    }
  }

  ForceStopAllPlayBacks() {
    const allOther = this.SelectedTestCasesToPlay$.getValue();
    if (allOther) {
      allOther.forEach((i) => {
        if (i.playing !== true) {
          i.completed = true;
        }
      });
    }
  }

  PlaybackComponentStringEmittedSocket(componentData: string) {
    let scenData = null;
    if (componentData.length > 14) {
      const scenString = componentData;
      scenData = JSON.parse(scenString);
      scenData.scenario_steps = scenData.scenario_steps.map(
        (item) =>
          (item = {
            ...item,
            playbackStatus: PlaybackActionStates.UNSET,
          } as IPlaybackAction)
      );
      scenData.scenario_steps[0].playbackStatus = PlaybackActionStates.WAITING;
    }
    if (scenData) {
      this.CurrentPlayBackData$.next(scenData);
      this.CurrentPlaybackComponents$.next(scenData.scenario_steps);
    }
  }
}
