import { Injectable } from "@angular/core";
import { Subject, BehaviorSubject } from "rxjs";
import moment from 'moment';

import {
  ActionEvents,
  Actions,
  BrowserMappings,
  PlaybackStates,
} from "../../shared/constants/enums";
import { IAssertion } from "../interfaces/IAssertion";

import { ApplicationsService } from "./applications.service";
import { ToasterService } from "./toaster.service";
import { SessionService } from "./session.service";
import { LoggingService } from "./logging.service";
import { PlayBackService } from "./playback.service";
import { SofyLiveService } from "./sofylive.service";
import { OverlayService } from "./overlay.service";
import {
  IDEMessageTypes,
  IDEPlayBackMessageTypes,
  DEVICE_ORIENTATION,
} from "src/app/shared/constants/constants";
import { DataDogService } from "./dataDog.service";
import { InternetService } from "./internet.service";
import { IActionEvent } from "src/app/lab-modules/home-module/action-menu/action-menu.component";
import { IVariableDictionary } from "../interfaces/VariableDictionary";
import { UtilsService } from "./utils.services";

@Injectable({
  providedIn: "root",
})
export class SessionSocketService {

  SessionSocket: WebSocket;
  SocketIDEMessages: Subject<string> = new Subject<string>();
  SocketMessagesText: Subject<string> = new Subject<string>();
  ScenarioGUID: BehaviorSubject<string> = new BehaviorSubject<string>("");
  trainingScenarioID: BehaviorSubject<string> = new BehaviorSubject<string>("");
  accessibilityTestCompleted: BehaviorSubject<number> =
    new BehaviorSubject<number>(0);
  canvasDisabled: BehaviorSubject<any> = new BehaviorSubject<boolean>(false);
  dynamicTestCaseVariable: BehaviorSubject<any> = new BehaviorSubject<any>([]);
  APIStepVariable: BehaviorSubject<any> = new BehaviorSubject<any>([]);
  fetchXMLLoader: BehaviorSubject<any> = new BehaviorSubject<boolean>(false);
  IsTemplate: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  firstPlaybackCompleted: BehaviorSubject<any> = new BehaviorSubject<boolean>(
    false
  );
  testFlightVariable: BehaviorSubject<any> = new BehaviorSubject<boolean>(
    false
  );
  applicationInstalled: BehaviorSubject<any> = new BehaviorSubject<boolean>(
    false
  );
  refreshAutomateRuns: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );
  SocketMessagesImage: Subject<any> = new Subject<any>();
  SessionVerifiedBySocket = false;
  isSocketInitialized = false;
  isPreviewMood: Subject<boolean> = new Subject<boolean>();
  isPlayBackRun: boolean = false;
  isTemplateMood: boolean = false;
  isManualTestMood: boolean = false;
  isAutomatedTestMood: boolean = false;
  firstPlayBackMood: boolean = false;
  isInsertedStepMood: boolean = false;
  insertedStepPopup: boolean = false;
  recordingService: any;
  isPromptRecordingComplete: Subject<boolean> = new Subject<boolean>();
  variableDictionary: BehaviorSubject<IVariableDictionary> =
    new BehaviorSubject<IVariableDictionary>({});
  isConnected: boolean;
  reconnectAttempts = 0;
  maxReconnectAttempts = 3; // Maximum number of reconnection attempts
  reconnectDelay = [5000, 10000]; // Delay in milliseconds between reconnection attempts
  // For Accessability
  isAccessabilityReportUploaded: Subject<boolean> = new Subject<boolean>();
  allowLandscapeInAccessibility: Subject<boolean> = new Subject<boolean>();
  isAccessabilityReportFailedUploaded: Subject<boolean> =
    new Subject<boolean>();

  isAccessabilityScanningStart: boolean = false;

  constructor(
    private appService: ApplicationsService,
    private toasterService: ToasterService,
    private sessionService: SessionService,
    private utilsService: UtilsService,
    private loggingService: LoggingService,
    private playBackService: PlayBackService,
    private sofyLiveService: SofyLiveService,
    private overLayService: OverlayService,
    private dataDogService: DataDogService,
    private internetService: InternetService
  ) { }

  // Connection sockets with IDE
  initializeSessionSocket(session: any): void {
    if (this.isSocketInitialized) {
      return;
    }
    this.isSocketInitialized = true;
    console.log("Initializing Session Socket Service");
    if (session) {
      this.connectToWebSocket(session);
    } else {
      console.log(
        "Session parameters are missing or not connected with socket connection."
      );
      this.dataDogService.sendMessage(
        "Session parameters are missing or not connected with socket connection.",
        {
          socketError: true,
          userGUiD: this.internetService.userGuid,
          deviceName: this.internetService.deviceName,
          sessionGuid: this.internetService.sessionGuid,
          email: this.internetService.email,
        }
      );
    }
  }

  isSocketOpen() {
    this.isConnected =
      this.SessionSocket && this.SessionSocket.readyState === WebSocket.OPEN;
    console.log("Connection Status:", this.isConnected);
    return this.isConnected;
  }

  connectToWebSocket(session: any) {
    console.log("Connecting socket ...");
    this.SessionSocket = new WebSocket("wss://" + session.DNS);
    if (this.SessionSocket && navigator.onLine) {
      this.registerEvents(session);
    } else {
      console.warn("connection not established...");
      let reconnectAttempts = 0; // Track the number of reconnect attempts
      const maxReconnectAttempts = 2; // Maximum number of reconnect attempts

      const reconnectFunction = () => {
        if (reconnectAttempts < maxReconnectAttempts) {
          setTimeout(() => {
            console.log(`${reconnectAttempts + 1}0 seconds`);
            if (!this.isSocketOpen() && !this.SessionVerifiedBySocket) {
              this.dataDogService.sendMessage(
                `Reconnecting socket after ${(reconnectAttempts + 1) * 5
                } seconds`,
                {
                  userGUiD: this.internetService.userGuid,
                  deviceName: this.internetService.deviceName,
                  sessionGuid: this.internetService.sessionGuid,
                  email: this.internetService.email,
                }
              );
              console.log(
                `Reconnecting after ${(reconnectAttempts + 1) * 5
                } second Connection Value: ${this.isSocketOpen()} ${this.SessionVerifiedBySocket
                }`
              );
              this.reconnectToWebSocket(session);
              reconnectAttempts++;
            }
          }, (reconnectAttempts + 1) * 5000); // Increasing delay for each attempt
        }
      };

      reconnectFunction();
    }
  }

  reconnectToWebSocket(session: any) {
    console.log("Reconnecting socket ...");
    this.toasterService.ShowToastMessage("Connection lost, Reconnecting...", 0);
    this.connectToWebSocket(session);
  }

  disconnectToWebSocket() {
    if (this.SessionSocket) {
      console.log("Disconnecting socket ...");
      this.SessionSocket.close();
    }
  }

  registerEvents(session: any) {
    // Socket connection opened
    this.SessionSocket.onopen = (_opened) => {
      this.dataDogService.sendMessage("Socket connection opened or connected", {
        userGUiD: this.internetService.userGuid,
        deviceName: this.internetService.deviceName,
        sessionGuid: this.internetService.sessionGuid,
        email: this.internetService.email,
        isAcquired: true,
        finalAcquire: true,
        sessionType: this.internetService.platForm,
      });

      this.handleOpenSocketCase(session.SessionGUID);
      this.imageStreamingRequest();
    };
    // Socket connection received Message
    this.SessionSocket.onmessage = (message) => {
      this.handleSocketMessage(message.data);
    };
    // Socket connection Error Message
    this.SessionSocket.onerror = (error) => {
      this.dataDogService.sendMessage("Error in socket connection", {
        errorMsg:  'Connection lost, please reconnect and try again.',
        socketError: true,
        userGUiD: this.internetService.userGuid,
        deviceName: this.internetService.deviceName,
        sessionGuid: this.internetService.sessionGuid,
        email: this.internetService.email,
      });
      console.log("Socket connection error occurred:");
      console.log(error);

      if (this.reconnectAttempts < this.maxReconnectAttempts) {
        this.reconnectAttempts++; // Increment the attempt counter
        if (this.reconnectAttempts === 3) {
          console.error(
            "Connection could not be established after 3 attempts."
          );

          this.overLayService.SetOverlayText(
            "Connection lost, please reconnect and try again."
          );
          this.overLayService.HideLoading();
          this.overLayService.ShowOverlay();
          return; // Stop further attempts
        }
        setTimeout(() => {
          this.reconnectToWebSocket(session);
        }, this.reconnectDelay[this.reconnectAttempts]);
      }
    };
  }
  private handleSocketMessage(data: string): void {
    if (this.isBase64(data)) {
      this.handleImageData(data);
    } else {
      this.handleNonImageData(data);
    }
  }

  private handleImageData(data: string): void {
    this.SocketMessagesImage.next(data);
  }

  private handleNonImageData(data: string): void {
    if (data.startsWith("fetch_data_emit")) {
      console.log("Received: fetch_data_emit");
    } else {
      console.log(
        `Received: ${moment().format("YYYY-MM-DD HH:mm:ss")} ${data}`
      );

      /* Use the confirmation of location update to set those lat lng values 
       that's in turn used to open updated location map */
      this.utilsService.updateLatLng(data);
    }
    this.EnumerateMessageEmitted(data);
  }

  isBase64(str: string): boolean {
    try {
      return btoa(atob(str)) === str;
    } catch (e) {
      return false;
    }
  }
  imageStreamingRequest() {
    this.sendSocketEvent("*");
  }

  showErrorWithoutTryAgain() {
    const overlayVal = this.overLayService.overlay.getValue();
    if (overlayVal) {
      this.overLayService.showError();
      this.overLayService.disableTryAgain();
    }
  }

  showError() {
    this.overLayService.showError();
  }
  receivedRecordingService(recordingService: any) {
    this.recordingService = recordingService;
  }
  //  Receiving Event from the IDE side
  EnumerateMessageEmitted(ideMessage: string): void {
    switch (true) {
      case ideMessage.startsWith(IDEMessageTypes.FETCH_DATA_RESP):
        this.handleFetchDataResponse(ideMessage);
        break;
      case ideMessage.includes("scenario_steps"):
        this.handleFetchDataResponse(ideMessage);
        break;
      case ideMessage.includes(IDEMessageTypes.DELETE_STEP_SUCCESSFUL):
        this.handleSuccessfulDeletion(ideMessage);
        break;
      case ideMessage.includes(IDEMessageTypes.DELETE_STEP_FAILED):
        this.handleFailedStepDeletion(ideMessage);
        break;
      case ideMessage.includes(IDEMessageTypes.ACCESSABILITY_TEST_START):
        this.handleAccessibilityStarted();
        break;
      case ideMessage.includes(IDEMessageTypes.ACCESSABILITY_TEST_COMPLETE):
        this.handleAccessibilityCompleted();
        break;
      case ideMessage.includes(IDEMessageTypes.ACCESSABILITY_REPORT_UPLOADED):
        this.handleAccessibilityReportUploaded();
        break;
      case ideMessage.includes(IDEMessageTypes.ACCESSABILITY_REPORT_GUID):
        this.handleAccessibilityReportGuid(ideMessage);
        break;
      case ideMessage.includes(
        IDEMessageTypes.ACCESSABILITY_REPORT_FAILED_TO_UPLOADED
      ):
        this.handleAccessibilityReportFailedUploaded();
        break;
      case ideMessage.startsWith(IDEMessageTypes.APP_FAIL):
        this.handleApplicationFailed(true);
        break;
      case ideMessage.startsWith(IDEMessageTypes.STATUS):
        this.handleRealTimeIdeLog(ideMessage);
        break;
      case ideMessage.startsWith(IDEMessageTypes.DOWNLOAD_DOWNLOAD):
        this.handleDownloadScenario(ideMessage);
        break;
      case ideMessage.startsWith(IDEMessageTypes.GET_THIRD_PARTY_APP):
        this.handleThirdPartyApps(ideMessage);
        break;
      case ideMessage.startsWith(IDEMessageTypes.DOWNLOAD):
        this.handleApplicationDownload(ideMessage);
        break;
      case ideMessage.startsWith(IDEMessageTypes.PLAYBACK):
        this.handlePlaybackStatus(ideMessage);
        break;
      case ideMessage.startsWith(IDEMessageTypes.UPLOAD_APP):
        this.handleAppUpload(ideMessage);
        break;
      case ideMessage.startsWith(IDEMessageTypes.DEVICE_LOG):
        this.handleDeviceLog(ideMessage);
        break;
      case ideMessage.startsWith(IDEMessageTypes.BUG_REPORT):
        this.handleBugReport(ideMessage);
        break;
      case ideMessage.startsWith(IDEMessageTypes.APPLICATION_STATE):
        this.handleApplicationState(ideMessage);
        break;
      case ideMessage.startsWith(IDEMessageTypes.END_SESSION):
        this.handleEndSession();
        break;
      case ideMessage.startsWith(IDEMessageTypes.STATUS_CHECK):
        this.handleSocketsStatus(ideMessage);
        break;
      case ideMessage.startsWith(IDEMessageTypes.SCENARIO_GUID):
        console.log("socket:343");
        this.handleUploadScenario(ideMessage);
        break;
      case ideMessage.startsWith(IDEMessageTypes.TEMPLATE_GUID):
        this.handleUploadScenario(ideMessage);
        break;
      case ideMessage.startsWith(IDEMessageTypes.FETCH_API_STEP_VARIABLES):
        this.handleFetchAPIStepVariables(ideMessage);
        break;
      case ideMessage.startsWith(IDEMessageTypes.FETCH_TEST_CASE_VARIABLES):
        this.handleFetchTestCaseVariables(ideMessage);
        break;
      case ideMessage.startsWith(IDEMessageTypes.PROMPT_RECORDING_COMPLETE):
        this.handlePromptRecordingComplete(ideMessage);
        break;
      case ideMessage.startsWith(IDEMessageTypes.SWIPE_UNTIL_START) || ideMessage.startsWith(IDEMessageTypes.SWIPE_UNTIL_CANCELLED) || ideMessage.startsWith(IDEMessageTypes.SWIPE_UNTIL_SAVED):
        this.handleSwipeUntilUIChanges(ideMessage);
        break;
      case ideMessage.includes(IDEMessageTypes.SWIPE_UNTIL_COMPONENT_DETAILS):
        this.handleSwipeUntilComponentData(ideMessage);
        break;
      case ideMessage.includes(IDEMessageTypes.SWIPE_UNTIL_SWIPE_COUNT):
        this.handleSwipeUntilComponentData(ideMessage);
        break;
      default:
        this.handleDefaultSocketsEvent(ideMessage);
        break;
    }
    this.handlePreviewMode(ideMessage);
    this.handleInsertedStepPopup(ideMessage);
  }

  // Prompt Recording Complete

  handlePromptRecordingComplete(ideMessage) {
    if (ideMessage.includes("promptRecord:complete")) {
      this.isPromptRecordingComplete.next(true);
    }
  }
  handleFetchDataResponse(ideMessage: string) {
    this.playBackService.PlaybackComponentStringEmittedSocket(ideMessage);
  }

  handleApplicationFailed(value: boolean) {
    this.applicationInstalled.next(value);
    this.toasterService.ShowToastMessage("Application load fail", 0, 3000);
  }
  handleRealTimeIdeLog(ideMessage: string) {
    const realtimeMessageObject = JSON.parse(ideMessage.substr(7));
    this.loggingService.sendLog(realtimeMessageObject.msg + "\n");
  }
  handleDownloadScenario(ideMessage: string) {
    if (ideMessage.includes("Complete")) {
      this.playBackService.SetPlaying();
    } else {
      this.playBackService.SetCurrentPlayableAutomatedTestCaseDownloading();
      const percent = parseFloat(ideMessage.substr(20, 2));
      this.playBackService.SetCurrentPlayableAutomatedTestCaseDownloadPercent(
        percent
      );
    }
  }
  handleThirdPartyApps(ideMessage: string) {
    JSON.parse(ideMessage.substr(17));
  }
  handlePlaybackStatus(ideMessage: string) {
    const playableCases =
      this.playBackService.SelectedTestCasesToPlay$.getValue();

    switch (true) {
      case ideMessage.includes(IDEPlayBackMessageTypes.PLAYBACK_STARTED):
        this.toasterService.ShowToastMessage("Playback Started", 1, 5000);
        this.playBackService.SetPlaying();
        this.RequestPlaybackComponents();
        break;

      case ideMessage.includes(IDEPlayBackMessageTypes.PLAYBACK_COMPLETED):
        this.toasterService.ShowToastMessage("Playback Completed", 1, 5000);
        // if (!this.recordingService.onboardingComplete)
        //   this.firstPlaybackCompleted.next(true);
        this.recordingService.onboardingComplete.subscribe((elem) => {
          if (elem) {
            this.firstPlaybackCompleted.next(true);
          }
        });
        this.playBackService.SetCurrentPlayableAutomatedTestCaseCompleted();
        break;

      case ideMessage.includes(
        IDEPlayBackMessageTypes.PLAYBACK_UPLOADING_RESULTS
      ):
        this.playBackService.SetCurrentPlayableAutomatedTestCaseUploading();
        break;

      case ideMessage.includes(
        IDEPlayBackMessageTypes.PLAYBACK_UPLOADED_RESULTS
      ):
        if (!playableCases) {
          this.playBackService.PlaybackStatus$.next(PlaybackStates.IDLE);
        }
        break;

      case ideMessage.includes("true") || ideMessage.includes("false"):
        this.toasterService.ShowToastMessage(ideMessage, 1, 5000);
        break;

      case ideMessage.includes(IDEPlayBackMessageTypes.PLAYBACK_STOPPED):
        this.toasterService.ShowToastMessage("Playback Stopped", 1, 5000);
        this.playBackService.stoppedPlayBack();
        break;

      case ideMessage.includes(IDEPlayBackMessageTypes.PLAYBACK_FAILED):
        this.toasterService.ShowToastMessage("Playback Failed", 0, 2000);
        if (!this.recordingService.onboardingComplete)
          this.firstPlaybackCompleted.next(true);
        this.playBackService.SetCurrentPlayableAutomatedTestCaseCompleted();
        if (!playableCases) {
          this.playBackService.PlaybackStatus$.next(PlaybackStates.IDLE);
        }
        break;

      default:
        const indStatusString = ideMessage.split(":")[1].split(" ");
        const ind = parseInt(indStatusString[0], 10);
        const stat = indStatusString[1];
        this.playBackService.CurrentPlaybackIndexStatus$.next({
          index: ind,
          status: stat,
        });
        break;
    }
  }
  handleApplicationDownload(ideMessage: string) {
    const x1 = ideMessage.substr(ideMessage.indexOf(":") + 1);
    if (x1 === "Complete") {
      this.appService.SetApplicationDownloadPercentage(100);
    } else {
      const perc = parseFloat(x1.split(".")[0]);
      this.appService.SetApplicationDownloadPercentage(perc);
    }
  }
  handleAppUpload(ideMessage: string) {
    const _app = this.appService.SelectedApplication.getValue();

    if (ideMessage.includes("UploadApp:true")) {
      this.toasterService.ShowToastMessage("Application Installed", 1, 5000);
      // this.playBackService.getAutomatedTestCaseList();
      this.appService.initiateApplicationInstallOrToggleSelected(
        _app.UserApplicationBuildList[_app.selectedBuildIndex].AppPackageGUID
      );
    } else {
      const Message = ideMessage.substring(ideMessage.indexOf(":") + 6);
      this.dataDogService.sendMessage(`Application installation failed ${this.internetService.sessionGuid}`, {
        error: Message
      })
      this.toasterService.ShowToastMessage(
        "Application Install Failed",
        0,
        2000,
        Message
      );
      this.appService.applicationInstallFailed(
        _app.UserApplicationBuildList[0].AppPackageGUID,
        true
      );
    }
  }
  handleDeviceLog(ideMessage: string) {
    this.sofyLiveService.issueCreateModal_DeviceLogUrl.next(
      ideMessage.substr(10)
    );
  }
  handleBugReport(ideMessage: string) {
    this.sofyLiveService.issueCreateModal_BugReportUrl.next(
      ideMessage.substr(10)
    );
  }
  handleApplicationState(ideMessage: string) {
    if (ideMessage.includes("Crashed")) {
      this.dataDogService.sendMessage(`Application Crashed ${this.internetService.sessionGuid}`, {
        error: ideMessage
      })
      this.toasterService.ShowToastMessage(
        "Application Crashed",
        0,
        5000,
        "Application crashed - trying to relaunch."
      );
    } else if (ideMessage.includes("Relaunched")) {
      this.dataDogService.sendMessage(`Application Relaunched ${this.internetService.sessionGuid}`, {
        error: ideMessage
      })
      this.toasterService.ShowToastMessage("Application Relaunched.", 1, 5000);
    }
  }
  handleEndSession() {
    this.sessionService.ReleaseSessionWithEffect().subscribe((session) => {
      this.overLayService.SetOverlayText("Session Ended");
      this.overLayService.HideLoading();
      this.overLayService.ShowOverlay();
    });
  }
  handleSocketsStatus(ideMessage: string) {
    if (ideMessage.includes("true")) {
      this.SessionVerifiedBySocket = true;
      this.SocketMessagesText.next(ideMessage);
    } else {
      this.disconnectToWebSocket();
    }
  }
  handleUploadScenario(ideMessage: string) {
    // this.recordingService.ResetAutomatedRecording();
    this.refreshAutomateRuns.next(true);
    this.SocketMessagesText.next(ideMessage);
  }
  handleFetchAPIStepVariables(ideMessage: string) {
    const variables = JSON.parse(ideMessage.split("FetchAPIStepVariables:")[1]);

    const updatedVariables = [];
    for (const key in variables) {
      if (variables.hasOwnProperty(key)) {
        const variable = variables[key];
        updatedVariables.push({
          id: key,
          variableValue: variable.value,
          variableName: variable.name,
          regex: 0,
          variableType: variable.variable_type,
        });
      }
    }
    this.APIStepVariable.next(updatedVariables);
  }
  handleFetchTestCaseVariables(ideMessage: string) {
    const variables = JSON.parse(
      ideMessage.split("FetchTestCaseVariables:")[1]
    );
    const updatedVariables = [];
    for (const key in variables) {
      if (variables.hasOwnProperty(key)) {
        const variable = variables[key];
        updatedVariables.push({
          id: key,
          variableValue: variable.value,
          variableName: variable.name,
          regex: 0,
        });
      }
    }
    this.dynamicTestCaseVariable.next(updatedVariables);
  }

  handleDefaultSocketsEvent(ideMessage: string) {
    this.SocketMessagesText.next(ideMessage);
  }
  handleLabPreview(ideMessage: string) {
    if (ideMessage.includes("Uploading Scenario")) {
      // this.isPreviewMood.next(true);
    }
  }
  handlePreviewMode(ideMessage: string): void {
    if (
      this.isTemplateMood === true ||
      this.isManualTestMood === true ||
      this.isInsertedStepMood === true
    ) {
      this.isPreviewMood.next(false);
    } else {
      this.handleLabPreview(ideMessage);
    }
  }
  handleInsertedStepPopup(ideMessage: string): void {
    if (this.isInsertedStepMood) {
      if (ideMessage.includes("InsertedSteps:true")) {
        this.insertedStepPopup = true;
        this.recordingService.ResetAutomatedRecording();
      }
    } else if (ideMessage.includes("Inserting Steps Failed")) {
      this.insertedStepPopup = true;

      this.recordingService.ResetAutomatedRecording();
    }
  }
  // Sending Event Lab side to IDE
  sendSocketEvent(message: any) {
    // Check if In accessbility report
    if (this.isAccessabilityScanningStart) {
      if (
        message ===
        `${ActionEvents.PerformAction} ${Actions.ROTATE_DEVICE} ${DEVICE_ORIENTATION.PORTRAIT}` ||
        message ===
        `${ActionEvents.PerformAction} ${Actions.ROTATE_DEVICE} ${DEVICE_ORIENTATION.LANDSCAPE}`
      ) {
        this.allowLandscapeInAccessibility.next(true);
      } else {
        this.allowLandscapeInAccessibility.next(false);
      }
    }

    if (this.SessionSocket?.readyState === WebSocket.OPEN) {
      this.SessionSocket.send(message);
      if (message !== "*") {
        this.logSentMessage(message);
      }
    } else {
      console.warn("WebSocket is not open. Message not sent.");
    }
  }
  private logSentMessage(message: string): void {
    const formattedTimestamp = moment().format("YYYY-MM-DD HH:mm:ss");
    console.log(`Sent: ${formattedTimestamp} ${message}`);
  }

  PlaybackScenarioByScenarioGUID(ScenarioGUID: string): void {
    this.sendSocketEvent("3" + " " + ScenarioGUID);
  }

  SendKeyInputEvent(keyAsciiCode: number): void {
    this.sendSocketEvent(
      ActionEvents.KeyIputEvent + " " + keyAsciiCode.toString()
    );
  }

  SendClickEmit(clickData: ArrayBuffer): void {
    this.sendSocketEvent(clickData);
  }

  SendManualEnd(testCaseResultId: number, testCaseResultStatus: number): void {
    this.sendSocketEvent(
      "26" +
      " " +
      testCaseResultId.toString() +
      " " +
      testCaseResultStatus.toString()
    );
  }

  SendFetchDeviceLogURL(): void {
    this.sendSocketEvent("23");
  }

  SendFetchBugReportURL(): void {
    this.sendSocketEvent("24");
  }
  sendTestFlightEvent(): void {
    this.sendSocketEvent(`420`);
  }

  //Delete Step while Recording index send
  sendIndexOfStepToDelete(index: number) {
    this.sendSocketEvent("107" + " " + index);
  }

  //Step deletion successful message
  handleSuccessfulDeletion(ideMessage: string) {
    this.toasterService.ShowToastMessage("Step Deleted Successfully", 1, 5000);
    this.SocketMessagesText.next(ideMessage);
  }

  //Step deletion failed while recording due to invalid index
  handleFailedStepDeletion(ideMessage: string) {
    this.toasterService.ShowToastMessage("Step Deletion Failed", 0, 5000);
    this.SocketMessagesText.next(ideMessage);
  }

  SendEndAutomatedRecordingSaveToCloud(
    automatedTestCaseName: string = "SOFY_SCENARIO",
    priority: any = "Low",
    description: any = "test case",
    tags: any = "No tags",
    insertedTestCaseGuid: any,
    insertedScenario: string = "testcase"
  ): void {
    const data = {
      name: automatedTestCaseName,
      priority: priority,
      description: description,
      tags: tags,
    };
    if (insertedTestCaseGuid !== "") {
      this.sendSocketEvent(
        "9" + " " + insertedTestCaseGuid + " " + insertedScenario
      );
      this.recordingService.ResetAutomatedRecording();
    } else {
      this.sendSocketEvent("10" + " " + JSON.stringify(data));
    }
  }

  SendEndAutomatedRecordingDontSaveToCloud(): void {
    this.sendSocketEvent("25");
  }

  SendForceStopPlaybacks(): void {
    if (this.SessionSocket) {
      this.sendSocketEvent("21");
    }
  }

  SendStartManualTesting(): void {
    this.sendSocketEvent("22");
  }

  SendStartManualTestingWithId(
    TestCaseResultId: number,
    notForDemo: boolean = true
  ): void {
    const socketCall = `22 ${TestCaseResultId.toString()} ${!notForDemo ? "false" : ""
      }`;
    this.sendSocketEvent(socketCall);
  }

  SendIssueCreation(liveIssueId: number): void {
    this.sendSocketEvent(
      ActionEvents.PerformAction + " " + "32" + " " + liveIssueId.toString()
    );
  }

  SendApplicationInstall(ApplicationPackageGUID: string): void {
    if (this.testFlightVariable.value === false) {
      this.sendSocketEvent(
        ActionEvents.UploadApp + " " + ApplicationPackageGUID
      );
    } else {
      this.sendTestFlightEvent();
    }
  }
  SendOpenBrowser(SessionGUID: string, browserName: string): void {
    this.sendSocketEvent(`20 ${SessionGUID}`);
    this.sendSocketEvent(`37 ${BrowserMappings[browserName]}`);
  }

  SendWebsiteLinkForBrowser(url: string): void {
    this.sendSocketEvent(`38 ${url}`);
  }

  QuerySessionStatus(): void {
    this.sendSocketEvent("status");
  }

  handleOpenSocketCase(sessionGUID: string): void {
    this.sendSocketEvent("session:" + sessionGUID);
    this.sendSocketEvent("status");
  }

  RequestAssertionComponents(): void {
    this.sendSocketEvent("15");
  }

  SendAppCleanUp(cleanUpValue = false): void {
    this.sendSocketEvent(`42 ${cleanUpValue}`);
  }

  SendAPIStep(data: any): void {
    this.sendSocketEvent(`43 ${data}`);
  }

  SendChangeOrientation(Orientation: string = "Portrait"): void {
    this.sendSocketEvent(
      ActionEvents.PerformAction +
      " " +
      Actions.ROTATE_DEVICE +
      " " +
      Orientation
    );
  }

  SendSetText(setTextValue: string): void {
    this.sendSocketEvent(
      ActionEvents.PerformAction + " " + Actions.SET_TEXT + " " + setTextValue
    );
  }

  SendSetTestVariable(testDataVariableId: string): void {
    this.sendSocketEvent(
      ActionEvents.PerformAction +
      " " +
      Actions.SET_TEST_DATA_VARIABLE +
      " " +
      testDataVariableId
    );
  }

  SendSetDyanmicTestCaseVariable(testDataVariableId: string): void {
    this.sendSocketEvent(
      ActionEvents.PerformAction +
      " " +
      Actions.SET_DYNAMIC_TEST_CASE_VARIABLE +
      " " +
      testDataVariableId
    );
  }

  SendAPIStepVariable(testDataVariableId: string): void {
    this.sendSocketEvent(
      ActionEvents.PerformAction +
      " " +
      Actions.SET_API_STEP_VARIABLE +
      " " +
      testDataVariableId
    );
  }

  SendAssertItem(assertionItem: IAssertion): void {
    if (assertionItem) {
      const assertionArr = [assertionItem];
      this.sendSocketEvent("16" + " " + JSON.stringify(assertionArr));
    }
  }

  PauseAndResumeRecord(action: string) {
    // true pause - false resume
    this.sendSocketEvent("48" + " " + JSON.stringify(action));
  }

  SendVariableNameItem(variableNameDetail): void {
    this.sendSocketEvent("40" + " " + JSON.stringify(variableNameDetail));
  }

  SendSelectedComponent(componentDetails): void {
    this.sendSocketEvent(`39  ${JSON.stringify(componentDetails)}`);
  }

  SendStartAutomatedRecording(
    isStartAsIs = false,
    isAutoHandleDialogs,
    promptRecordingFeed = "",
    isSimplePromptMode = false
  ): void {
    if (promptRecordingFeed.length > 0) {
      let splitString = promptRecordingFeed.split("\n");
      let text = "";
      splitString.forEach((elem, index) => {
        if (index != splitString.length - 1 && elem) {
          text += `'${elem}'||`;
        } else if (elem) {
          text += `'${elem}'`;
        }
      });
      promptRecordingFeed = `{${text}}`;
    }

    if (isSimplePromptMode) {
      this.sendSocketEvent(
        `${ActionEvents.StartRecording.toString()} ${isStartAsIs} ${isAutoHandleDialogs} ${isSimplePromptMode} ${promptRecordingFeed}`
      );
    } else {
      this.sendSocketEvent(
        `${ActionEvents.StartRecording.toString()} ${isStartAsIs} ${isAutoHandleDialogs} ${promptRecordingFeed}`
      );
    }
  }

  SendPerformActionEvent(event: IActionEvent): void {
    let eventStr = ActionEvents.PerformAction.toString();
    if (event.ActionEventEnum) {
      eventStr += " " + event.ActionEventEnum.toString();
    }
    if (event.Value) {
      eventStr += " " + event.Value;
    }
    this.sendSocketEvent(eventStr);
  }

  OnSessionInit(): void {
    this.sessionService.updateSocketConnectionStatus(
      this.internetService.sessionGuid
    );
    this.sendUserGuid();
    this.SendRefreshCleanUp();
    this.GetSessionThirdPartyApps();
    this.SendEndAutomatedRecordingDontSaveToCloud();
  }

  GetSessionThirdPartyApps(): void {
    this.sendSocketEvent("12");
  }

  GetCurrentMode(): void {
    this.sendSocketEvent("6");
  }

  sendUserGuid(): void {
    this.sendSocketEvent(`45 ${this.internetService.userGuid}`);
  }

  SendRefreshCleanUp(): void {
    this.sendSocketEvent("14");
  }

  SendGetResourceThreshold(): void {
    this.sendSocketEvent("17");
  }

  SendSetResourceThreshold(value: string): void {
    this.sendSocketEvent("18 " + value);
  }

  RequestPlaybackComponents(): void {
    this.sendSocketEvent("5 0");
  }

  SendStartDeviceLogs(): void {
    if (!this.SessionSocket) {
      return;
    }
    this.sendSocketEvent("8");
  }

  SendStopDeviceLogs(): void {
    if (!this.SessionSocket) {
      return;
    }
    this.sendSocketEvent("19");
  }

  SendMockLocation(latitude: string, longitude: string): void {
    this.sendSocketEvent(`69 ${latitude} ${longitude}`);
  }

  ForceEndIDESession(): void {
    if (!this.SessionSocket) {
      return;
    }
    this.sendSocketEvent("4");
  }

  GetDynamicVariables(): void {
    this.sendSocketEvent("41");
  }

  GetAPISetVariable(): void {
    this.sendSocketEvent("44");
  }

  sendAppChange(appName: string = "") {
    this.sendSocketEvent(`1 420 ${appName}`);
  }

  // Accessability Feature Sockets Events
  handleAccessibilityCompleted() {
    this.accessibilityTestCompleted.next(1);
    this.canvasDisabled.next(false);
  }

  handleAccessibilityStarted() {
    this.canvasDisabled.next(true);
  }
  handleAccessibilityReportUploaded() {
    this.isAccessabilityReportUploaded.next(true);
  }
  handleAccessibilityReportGuid(ideMessage: any) {
    this.SocketIDEMessages.next(ideMessage);
  }
  handleAccessibilityReportFailedUploaded() {
    this.isAccessabilityReportFailedUploaded.next(true);
  }
  sendStartAccessibilityMode() {
    this.sendSocketEvent("100");
  }
  sendStopAccessibilityMode() {
    this.sendSocketEvent("101");
  }
  saveAccessibilityReport(
    reportName: string,
    reportGuid: string,
    reportDescription: string = "test case",
    reportTags: any = []
  ) {
    const data = {
      report_name: reportName,
      report_guid: reportGuid,
      report_description: reportDescription,
      report_tags: reportTags,
    };
    this.sendSocketEvent(`102 ${JSON.stringify(data)}`);
  }
  pauseAccessibility() {
    this.sendSocketEvent("103");
  }
  resumeAccessibility() {
    this.sendSocketEvent("104");
  }
  sessionExpired() {
    this.sendSocketEvent("105");
  }
  // * -- SWIPE UNTIL FEATURE SOCKET EVENTS -- *
  sendStartSwipeUntil() {
    this.sendSocketEvent("106");
  }
  // sends socket event to let coreops know not to save the swipe-until actions performed
  sendCancelSwipeUntil() {
    this.sendSocketEvent("109");
  }
  sendAddSwipeUntil(swipeUntilData: string) {
    this.sendSocketEvent("109 " + swipeUntilData);
  }
  handleSwipeUntilComponentData(ideMessage: string) {
    this.SocketIDEMessages.next(ideMessage);
  }
  handleSwipeUntilUIChanges(ideMessage: string) {
    if (ideMessage.startsWith("Swipe Until Mode Started")) {
      this.recordingService.manageSwipeUntilUI(true);
    }
    if (ideMessage.startsWith("Saved Swipe Until Step") || ideMessage.includes("Swipe Until Step Cancelled")) {
      this.recordingService.manageSwipeUntilUI(false);
      this.utilsService.stepActionOverlay.next('');
    }
  }
}
