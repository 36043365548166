export class Constants {
  public static INPUT_TYPE = {
    TEXT: "text",
    NUMBER: "number",
    PASSWORD: "password",
    DROPDOWN: "dropdown",
    LEVEL2_DROPDOWN: "leveldropdown",
    TEXTAREA: "textarea",
    FILE: "file",
    CALENDAR: "date",
    RADIO: "radio",
    CHECKBOX: "checkbox",
    TIME: "time",
    MAXDATE: "12/31/2099",
  };
  public static DROPDOWN_DATATYPES = {
    IMAGE: "image",
    SVG: "svg",
    SYBMBOL: "symbol",
    CLASS: "class",
  };
  public static LocalStorageVariables = {
    UserID: "UserID",
    UTMData: "UTMMetaData",
    IsUserVerified: "isUserVerified",
    UserGUID: "UserGUID",
  };
  public static SessionStoraegVariable = {
    ApplicationGUID: "applicationGUID",
    ReleaseID: "releaseID",
    SubscriptionDaysLeft: "subscriptionDaysLeft",
  };
  public static EDITABLE_ACTIONS = {
    ASSERT_EXISTS_EXACT_MATCH: "Test component is present",
    ASSERT_NOT_EXISTS_EXACT_MATCH: "Test component is not present",
    ASSERT_ENABLED: "Test component is enabled",
    ASSERT_NOT_ENABLED: "Test component is disabled",
    ASSERT_CHECKED: "Test component is checked",
    ASSERT_UNCHECKED: "Test component is unchecked",
    COMPONENT_CONTAINS: "Test component has content",
    ASSERT_TEST_DATA_VARIABLE: "Should be equal variable",
    ASSERT_TEST_CASE_VARIABLE: "Should be equal test case variable",
    ASSERT_API_STEP_VARIABLE: "Should be equal api variable",
    ASSERT_EQUALS_EXACT_MATCH: "Should be equal",
    ASSERT_NOT_EQUALS_EXACT_MATCH: "Should not be equal",
    ASSERT_EQUALS_SIMILAR: "Should contain",
    ASSERT_NOT_EQUALS_SIMILAR: "Should not contain",
    ASSERT_TEST_DATA_VARIABLE_EXACT_MATCH: "Should be equal data variable",
    ASSERT_TEST_DATA_VARIABLE_NOT_EXACT_MATCH:
      "Should not be equal data variable",
    ASSERT_TEST_CASE_VARIABLE_NOT_EQUALS_EXACT_MATCH:
      "Should not be equal to test case variable",
    ASSERT_API_STEP_VARIABLE_NOT_EQUALS_EXACT_MATCH:
      "Should not be equal to api variable",
    ASSERT_TEST_DATA_VARIABLE_EQUALS_SIMILAR: "Should contain data variable",
    ASSERT_TEST_CASE_VARIABLE_EQUALS_SIMILAR:
      "Should contain test case variable",
    ASSERT_API_STEP_VARIABLE_EQUALS_SIMILAR: "Should contain api variable",
    ASSERT_TEST_DATA_VARIABLE_NOT_EQUALS_SIMILAR:
      "Should not contain data variable",
    ASSERT_TEST_CASE_VARIABLE_NOT_EQUALS_SIMILAR:
      "Should not contain test case variable",
    ASSERT_API_STEP_VARIABLE_NOT_EQUALS_SIMILAR:
      "Should not contain api variable",
    ASSERT_TEST_CASE_VARIABLE_NOT_EXACT_MATCH:
      "Should not be equal test case variable",
    ASSERT_API_STEP_VARIABLE_NOT_EXACT_MATCH: "Should not equal api variable",
    ASSERT_NUMERIC_EQUAL: "Test component is equal to",
    ASSERT_NUMERIC_GREATER: "Test component is greater than",
    ASSERT_NUMERIC_GREATER_EQUAL: "Test component is greater than or equal to",
    ASSERT_NUMERIC_LESSER: "Test component is less than",
    ASSERT_NUMERIC_LESSER_EQUAL: "Test component is less than or equal to",
    ASSERT_NUMERIC_INBETWEEN: "Test component is in-between",
    ASSERT_NUMERIC_EQUAL_TEST_DATA_VARIABLE:
      "Numeric Test component is equal to data variable",
    ASSERT_NUMERIC_GREATER_TEST_DATA_VARIABLE:
      "Numeric Test component is greater than data variable",
    ASSERT_NUMERIC_GREATER_EQUAL_TEST_DATA_VARIABLE:
      "Numeric Test component is greater than or equal to data variable",
    ASSERT_NUMERIC_LESSER_TEST_DATA_VARIABLE:
      "Numeric Test component is less than data variable",
    ASSERT_NUMERIC_LESSER_EQUAL_TEST_DATA_VARIABLE:
      "Numeric Test component is less than or equal to data variable",
    ASSERT_NUMERIC_INBETWEEN_TEST_DATA_VARIABLE:
      "Numeric Test component is in-between data variable",
  };
  public static ALL_ACTIONS = {
    NO_OP: "NO_OP",
    APP_LAUNCH: "APP_LAUNCH",
    BACK_KEY: "BACK_KEY",
    "SWIPE(3)": "SWIPE(3)",
    SEEK_BAR: "SEEK_BAR",
    CLICK: "CLICK",
    LONG_CLICK: "LONG_CLICK",
    SET_TEXT: "SET_TEXT",
    SET_API_STEP_VARIABLE: "SET_API_STEP_VARIABLE",
    ADD_TEST_DATA: "ADD_TEST_DATA",
    SET_TEST_CASE_VARIABLE: "SET_TEST_CASE_VARIABLE",
    UNEXPECTED_SCREEN: "UNEXPECTED_SCREEN",
    APP_CLOSED: "APP_CLOSED",
    LOADING_WAIT: "LOADING_WAIT",
    ENTER_KEY: "ENTER_KEY",
    RESET_APP: "RESET_APP",
    PICK_DATE: "PICK_DATE",
    EXPECTED: "EXPECTED",
    GOOGLEPERMISSIONS: "GOOGLEPERMISSIONS",
    HOME_KEY: "HOME_KEY",
    ASSERTION: "ASSERTION",
    ASSERT_EXISTS_SIMILAR: "ASSERT_EXISTS_SIMILAR",
    ASSERT_EXISTS_EXACT_MATCH: "Test component is present",
    ASSERT_NOT_EXISTS_EXACT_MATCH: "Test component is not present",
    ASSERT_ENABLED: "Test component is enabled",
    ASSERT_NOT_ENABLED: "Test component is disabled",
    ASSERT_CHECKED: "Test component is checked",
    ASSERT_UNCHECKED: "Test component is unchecked",
    ASSERT_TEST_DATA_VARIABLE_EXACT_MATCH: "Should be equal variable",
    ASSERT_EQUALS_EXACT_MATCH: "Should be equal",
    ASSERT_NOT_EQUALS_EXACT_MATCH: "Should not be equal",
    ASSERT_EQUALS_SIMILAR: "Should contain",
    ASSERT_NOT_EQUALS_SIMILAR: "Should not contain",
    CLEAR_TEXT: "CLEAR_TEXT",
    TOGGLE_WIFI: "TOGGLE_WIFI",
    TIME_DELAY: "TIME_DELAY",
    TEMPLATE: "TEMPLATE",
  };
  public static SUB_ASSERTS = [
    "Should be equal",
    "Should not be equal",
    "Should contain",
    "Should not contain",
  ];
  public static TEST_CASE_DETAIL = [
    "Take a moment to scroll through all of your test steps. Click on any step to make an edit to a target or action, and you can also validate your run to ensure your sequence finishes.",
  ];
  // ASSERT ENGINE
  public static ASSERT_ENGINE_ACTIONS_DROPDOWN = [
    "Component",
    "Text",
    "Numeric",
  ];
  public static ASSERT_ENGINE_COMPONENT_ACTIONS = [
    "is present",
    "is not present",
    "is enabled",
    "is disabled",
    "is checked",
    "is unchecked",
  ];
  public static ASSERT_ENGINE_TEXT_ACTIONS = ["has content"];
  public static ASSERT_ENGINE_NUMERIC_ACTIONS = [
    "is equal to",
    "is greater than",
    "is greater than or equal to",
    "is less than",
    "is less than or equal to",
    "is in-between",
  ];
  public static NUMERIC_ASSERT_ACTIONS = [
    "Test component is equal to",
    "Test component is greater than",
    "Test component is greater than or equal to",
    "Test component is less than",
    "Test component is less than or equal to",
    "Test component is in-between",
    "Numeric Test component is equal to data variable",

    "Numeric Test component is greater than data variable",
    "Numeric Test component is greater than or equal to data variable",
    "Numeric Test component is less than data variable",
    "Numeric Test component is less than or equal to data variable",
    "Numeric Test component is in-between data variable",
  ];
  public static ASSERT_ENGINE_ACTIONS = ["Fail Test", "Warn and Continue"];
  public static NAVIGATION_URL = {
    LOCALHOST: "http://localhost:4100/app/",
    DEV_TEST: "https://devtest.sofy.ai/app/",
    PRE_PROD: "https://portal-sofy-stag-01-pre-prod.azurewebsites.net/app/",
    PROD: "https://portal.sofy.ai/app/",
  };
  public static VARIABLE_DATA_TYPE = {
    STRING: "String",
    INTEGER: "Integer",
    FLOAT: "Float",
  };
  public static VARIABLE_DATA_TYPE_LIST = [
    {
      title: this.VARIABLE_DATA_TYPE.STRING,
      value: this.VARIABLE_DATA_TYPE.STRING,
    },
    {
      title: this.VARIABLE_DATA_TYPE.INTEGER,
      value: this.VARIABLE_DATA_TYPE.INTEGER,
    },
    {
      title: this.VARIABLE_DATA_TYPE.FLOAT,
      value: this.VARIABLE_DATA_TYPE.FLOAT,
    },
  ];
  public static  PATTERN_DATA_TYPE_LIST =  [
    {
      title: this.VARIABLE_DATA_TYPE.STRING,
      value: this.VARIABLE_DATA_TYPE.STRING,
    },
    {
      title: this.VARIABLE_DATA_TYPE.INTEGER,
      value: this.VARIABLE_DATA_TYPE.INTEGER,
    }
  ];
}

export enum REVERSE_ASSERT_MAPPING {
  "Test component is present" = "ASSERT_EXISTS_EXACT_MATCH",
  "Test component is not present" = "ASSERT_NOT_EXISTS_EXACT_MATCH",
  "Test component is enabled" = "ASSERT_ENABLED",
  "Test component is disabled" = "ASSERT_NOT_ENABLED",
  "Test component is checked" = "ASSERT_CHECKED",
  "Test component is unchecked" = "ASSERT_UNCHECKED",
  "ASSERT_EXISTS_SIMILAR" = "ASSERT_EXISTS_SIMILAR",
  "Should be equal" = "ASSERT_EQUALS_EXACT_MATCH",
  "Test component has content" = "COMPONENT_CONTAINS",
  "Should be equal test case variable" = "ASSERT_TEST_CASE_VARIABLE",
  "Should be equal variable" = "ASSERT_TEST_DATA_VARIABLE",
  "Should not be equal" = "ASSERT_NOT_EQUALS_EXACT_MATCH",
  "Should contain" = "ASSERT_EQUALS_SIMILAR",
  "Should be equal api variable" = "ASSERT_API_STEP_VARIABLE",
  "Should not contain" = "ASSERT_NOT_EQUALS_SIMILAR",
  "Should be equal data variable" = "ASSERT_TEST_DATA_VARIABLE_EXACT_MATCH",
  "Should not be equal data variable" = "ASSERT_TEST_DATA_VARIABLE_NOT_EXACT_MATCH",
  "Should contain data variable" = "ASSERT_TEST_DATA_VARIABLE_EQUALS_SIMILAR",
  "Should contain test case variable" = "ASSERT_TEST_CASE_VARIABLE_EQUALS_SIMILAR",
  "Should contain api variable" = "ASSERT_API_STEP_VARIABLE_EQUALS_SIMILAR",
  "Should not equal api variable" = "ASSERT_API_STEP_VARIABLE_NOT_EXACT_MATCH",
  "Should not contain data variable" = "ASSERT_TEST_DATA_VARIABLE_NOT_EQUALS_SIMILAR",
  "Should not contain test case variable" = "ASSERT_TEST_CASE_VARIABLE_NOT_EQUALS_SIMILAR",
  "Should not contain api variable" = "ASSERT_API_STEP_VARIABLE_NOT_EQUALS_SIMILAR",
  "Should not be equal test case variable" = "ASSERT_TEST_CASE_VARIABLE_NOT_EXACT_MATCH",
  "Should not be equal to api variable" = "ASSERT_API_STEP_VARIABLE_NOT_EQUALS_EXACT_MATCH",
  "Should not be equal to test case variable" = "ASSERT_TEST_CASE_VARIABLE_NOT_EQUALS_EXACT_MATCH",
  "Test component is equal to" = "ASSERT_NUMERIC_EQUAL",
  "Test component is greater than" = "ASSERT_NUMERIC_GREATER",
  "Test component is greater than or equal to" = "ASSERT_NUMERIC_GREATER_EQUAL",
  "Test component is less than" = "ASSERT_NUMERIC_LESSER",
  "Test component is less than or equal to" = "ASSERT_NUMERIC_LESSER_EQUAL",
  "Test component is in-between" = "ASSERT_NUMERIC_INBETWEEN",
  "Numeric Test component is equal to data variable" = "ASSERT_NUMERIC_EQUAL_TEST_DATA_VARIABLE",
  "Numeric Test component is greater than data variable" = "ASSERT_NUMERIC_GREATER_TEST_DATA_VARIABLE",
  "Numeric Test component is greater than or equal to data variable" = "ASSERT_NUMERIC_GREATER_EQUAL_TEST_DATA_VARIABLE",
  "Numeric Test component is less than data variable" = "ASSERT_NUMERIC_LESSER_TEST_DATA_VARIABLE",
  "Numeric Test component is less than or equal to data variable" = "ASSERT_NUMERIC_LESSER_EQUAL_TEST_DATA_VARIABLE",
  "Numeric Test component is in-between data variable" = "ASSERT_NUMERIC_INBETWEEN_TEST_DATA_VARIABLE",
}

const PROJECT_NAME = "LAB";

const SUBSCRIPTIONS = {
  INHOUSE: "INHOUSE",
  ENTERPRISE: "ENTERPRISE",
  TRIAL: "TRIAL",
};

const INTERNET_STATUS = {
  CONNECTED: "CONNECTED",
  DISCONNECTED: "DISCONNECTED",
  SLOW: "SLOW",
  COULD_NOT_DETECT: "COULD_NOT_DETECT",
  RECONNECTED: "RECONNECTED",
};

const INTERNET_STATUS_EVENTS = {
  SLOW_INTERNET: "SLOW_INTERNET",
  INTERNET_RECONNECTED: "INTERNET_RECONNECTED",
  COULD_NOT_DETECT_INTERNET_SPEED: "COULD_NOT_DETECT_INTERNET_SPEED",
  INTERNET_SPEED_AT_START: "INTERNET_SPEED_AT_START",
};

const ISSUETYPES = [
  {
    key: "PRODUCTION",
    value: "Product Issue",
  },
  {
    key: "TEST",
    value: "Test Issue",
  },
  {
    key: "PERFORMANCE",
    value: "Performance",
  },
  {
    key: "VISUALQUALITY",
    value: "Visual Quality",
  },
  {
    key: "CRASH",
    value: "Crash",
  },
  {
    key: "FUNCTIONAL",
    value: "Functional",
  },
  {
    key: "NON-FUNCTIONAL",
    value: "Non-Functional",
  },
];

const REQUEST_TYPES = {
  get: "GET",
  post: "POST",
  put: "PUT",
  patch: "PATCH",
  delete: "DELETE",
};

enum RESULTS_PLAYBACK_STATUS {
  "passed" = 1,
  "passed_with_errors" = 1,
  "passed_with_warnings" = 1,
  "failed" = 0,
  "incomplete" = 2,
  "in_complete" = 2,
  "not executed" = 2,
  "inprogress" = 3,
  "running" = 3,
  "in_progress" = 3,
  "submitted for qbot" = 4,
  "internal_failed" = 5,
  "install_failed_invalid_provisioning_profile" = 6,
  "failed_controller_restart" = 7,
  "failed-session expired" = 8,
  "install_failed" = 7,
  "install_failed_no_matching_abis" = 7,
  "failed_scenario_json_not_found" = 5,
}

enum RESULTS_STATUS_STRING {
  "passed" = "Test Cases Passed",
  "passed_with_errors" = "Passed With Warning",
  "passed_with_warnings" = "Passed With Warning",
  "failed" = "Test Cases Failed",
  "incomplete" = "Incomplete",
  "inprogress" = "In Progress",
  "not executed" = "Not Executed",
  "running" = "In Progress",
  "in_progress" = "In Progress",
  "submitted for qbot" = "In Queue",
  "internal_failed" = "Internal Failed",
  "install_failed_invalid_provisioning_profile" = "Invalid Profile",
  "failed_controller_restart" = "Failed Controller Restart",
  "failed-session expired" = "Failed, Session Expired",
  "install_failed" = "Installation Failed",
  "in_complete" = "Incomplete",
  "install_failed_no_matching_abis" = "Installation Failed",
  "failed_scenario_json_not_found" = "Internal Failed",
}

const DEVICE_ORIENTATION = {
  PORTRAIT: "Portrait",
  LANDSCAPE: "Landscape",
};

const MANUAL_TESTRUN_RESULTS = {
  FAILED: 0,
  PASSED: 1,
  CANCELLED: 2,
};
const LIST_OF_ASSERTS = [
  "ASSERTION",
  "ASSERT_EXISTS_EXACT_MATCH",
  "ASSERT_EQUALS_EXACT_MATCH",
  "ASSERT_NOT_EQUALS_EXACT_MATCH",
  "ASSERT_EXISTS_SIMILAR",
  "ASSERT_EQUALS_SIMILAR",
  "ASSERT_NOT_EQUALS_SIMILAR",
  "ASSERT_ENABLED",
  "ASSERT_NOT_ENABLED",
  "ASSERT_CHECKED",
  "ASSERT_UNCHECKED",
  "ASSERT_TEST_DATA_VARIABLE_EXACT_MATCH",
  "ASSERT_TEST_DATA_VARIABLE_EXACT_MATCH",
  "ASSERT_TEST_CASE_VARIABLE",
  "ASSERT_API_STEP_VARIABLE",
  "ASSERT_NOT_EXISTS_EXACT_MATCH",
  "ASSERT_TEST_DATA_VARIABLE_NOT_EXACT_MATCH",
  "ASSERT_TEST_CASE_VARIABLE_NOT_EXACT_MATCH",
  "ASSERT_API_STEP_VARIABLE_NOT_EXACT_MATCH",
  "ASSERT_TEST_DATA_VARIABLE_EQUALS_SIMILAR",
  "ASSERT_TEST_CASE_VARIABLE_EQUALS_SIMILAR",
  "ASSERT_API_STEP_VARIABLE_EQUALS_SIMILAR",
  "ASSERT_TEST_DATA_VARIABLE_NOT_EQUALS_SIMILAR",
  "ASSERT_TEST_CASE_VARIABLE_NOT_EQUALS_SIMILAR",
  "ASSERT_API_STEP_VARIABLE_NOT_EQUALS_SIMILAR",
  "ASSERT_API_STEP_VARIABLE_NOT_EXACT_MATCH",
  "ASSERT_NUMERIC_EQUAL",
  "ASSERT_NUMERIC_GREATER",
  "ASSERT_NUMERIC_GREATER_EQUAL",
  "ASSERT_NUMERIC_LESSER",
  "ASSERT_NUMERIC_LESSER_EQUAL",
  "ASSERT_NUMERIC_INBETWEEN",
  "ASSERT_NUMERIC_EQUAL_TEST_DATA_VARIABLE",
  "ASSERT_NUMERIC_GREATER_TEST_DATA_VARIABLE",
  "ASSERT_NUMERIC_GREATER_EQUAL_TEST_DATA_VARIABLE",
  "ASSERT_NUMERIC_LESSER_TEST_DATA_VARIABLE",
  "ASSERT_NUMERIC_LESSER_EQUAL_TEST_DATA_VARIABLE",
  "ASSERT_NUMERIC_INBETWEEN_TEST_DATA_VARIABLE",
];

export const TEST_DATA_PATTERNS = {
  NUMERIC: "{{ numeric(n) }}",
  ALPHABETIC: "{{ alphabetic(n) }}",
  ALPHANUMERIC: "{{ alphanumeric(n) }}",
  DATE: "{{ date(n, DD-MM-YYYY) }}",
};

export const TIMEREVENTS = {
  COMPLETED_SESSION: "EXPIRED",
  ENDED_SESSION: "SIGTERM",
  STARTED_SESSION: "START",
  RESET_SESSION: "RESET",
  UPDATE_SESSION: "UPDATE",
};
// "START", "SIGTERM", "EXPIRED", "RESET", "UPDATE"]

export const StepActions = {
  SET_lOCATION: "Set Location",
  SET_TEXT: 'Set Text',
  SWIPE_UNTIL: 'Swipe Until',
  ASSERT: 'Assert',
  THRESHOLD: 'Threshold',
  TEST_CASE_VARIABLE: 'Save Test Case Variable',
  ELEMENT_EXPLORER: 'Element Explorer',
  API_STEP: 'API Step',
}

export const IDEMessageTypes = {
  FETCH_DATA_RESP: "fetch_data_resp",
  STATUS: "Status:",
  DOWNLOAD_DOWNLOAD: "Download-Download:",
  GET_THIRD_PARTY_APP: "GetThirdPartyApp",
  DOWNLOAD: "Download",
  PLAYBACK: "Playback",
  UPLOAD_APP: "UploadApp",
  DEVICE_LOG: "DeviceLog:",
  BUG_REPORT: "BugReport:",
  SAVE_SCENARIO: "SaveScenario",
  APPLICATION_STATE: "ApplicationState",
  END_SESSION: "end_session:true",
  DELETE_STEP_SUCCESSFUL: "Step Deleted Successfully",  //Step Deletion Successful message during the recording
  DELETE_STEP_FAILED: "Delete Step Failed due to Invalid Index", //Delete Step Failed due to invalid index during the recording
  STATUS_CHECK: "status",
  UPLOAD_SCENARIO: "UploadScenario",
  SCENARIO_GUID: "Scenario GUID: ",
  TEMPLATE_GUID: "Template GUID: ",
  TRAINING_SCENARIO_ID: "TrainingScenarioId: ",
  FETCH_API_STEP_VARIABLES: "FetchAPIStepVariables",
  FETCH_TEST_CASE_VARIABLES: "FetchTestCaseVariables",
  PROMPT_RECORDING_COMPLETE: "promptRecord:complete",
  ACCESSABILITY_TEST_COMPLETE:
    "Accessibility Test Execution Complete for the current screen",
  ACCESSABILITY_TEST_START:
    "Starting Accessibility Test Execution for current screen",
  APP_FAIL: "Application load fail",
  ACCESSABILITY_REPORT_UPLOADED: "Uploaded Accessibility Reports Successfully ",
  ACCESSABILITY_REPORT_FAILED_TO_UPLOADED:
    "Failed to upload Accessibility Reports",
  ACCESSABILITY_REPORT_GUID: "report_guid",
  SWIPE_UNTIL_COMPONENT_DETAILS: "Clicked_Component_Details_For_SwipeUntil_in_Lab",
  SWIPE_UNTIL_SWIPE_COUNT: "Swipe performed successfully, swipe_count:",
  SWIPE_UNTIL_START:"Swipe Until Mode Started",
  SWIPE_UNTIL_CANCELLED:"Swipe Until Step Cancelled",
  SWIPE_UNTIL_SAVED:"Saved Swipe Until Step",
};
export const IDEPlayBackMessageTypes = {
  PLAYBACK_STARTED: "Started",
  PLAYBACK_COMPLETED: "completed",
  PLAYBACK_STOPPED: "stopped",
  PLAYBACK_FAILED: "failed",
  PLAYBACK_UPLOADING_RESULTS: "Uploading results",
  PLAYBACK_UPLOADED_RESULTS: "Uploaded",
};

export const NetworkProfiles = [
  {
    name: "Default",
    speed: "-",
    port: "-",
    dropRate: "0",
    concurrency: "-",
    close: false,
    sleep: "0",
  },
  {
    name: "2G-Average",
    speed: "3000",
    port: "5000",
    dropRate: "0",
    concurrency: "500",
    close: false,
    sleep: "0",
  },
  {
    name: "2G-Good",
    speed: "6000",
    port: "5001",
    dropRate: "0",
    concurrency: "500",
    close: false,
    sleep: "0",
  },
  {
    name: "3G-Average",
    speed: "50000",
    port: "5002",
    dropRate: "0",
    concurrency: "500",
    close: false,
    sleep: "0",
  },
  {
    name: "4G-Low",
    speed: "100000",
    port: "5003",
    dropRate: "0",
    concurrency: "500",
    close: false,
    sleep: "0",
  },
  {
    name: "4G-Good",
    speed: "200000",
    port: "5004",
    dropRate: "0",
    concurrency: "500",
    close: false,
    sleep: "0",
  },
  {
    name: "Broadband-Average",
    speed: "400000",
    port: "5005",
    dropRate: "0",
    concurrency: "500",
    close: false,
    sleep: "0",
  },
  {
    name: "Broadband-Good",
    speed: "800000",
    port: "5006",
    dropRate: "0",
    concurrency: "500",
    close: false,
    sleep: "0",
  },
];

export const AppUploadErrorMsg = {
  UNSUPPORTED_FILES_UPLOADED:
    "Files with extensions other than APK or IPA are not supported. Reupload after ensuring that the extension and type of the application file being uploaded is either an apk or an ipa.",
  WRONG_FILE_UPLOADED_FOR_ANDROID:
    "Cannot upload IPA files on Android sessions.",
  WRONG_FILE_UPLOADED_FOR_IOS: "Cannot upload APK files on iOS sessions.",
  SUBSCRIPTIONS_PLAN_ERROR:
    "Your subscription plan does not support the uploaded application build type. You can either upload the file type specified in your subscription or modify your subscription by reaching out",
  APPLICATION_LIMIT_EXCEED:
    "You have reached the maximum number of allowed applications for this subscription. In order to extend the Application upload limit, please reach out",
};

export {
  PROJECT_NAME,
  REQUEST_TYPES,
  INTERNET_STATUS,
  INTERNET_STATUS_EVENTS,
  ISSUETYPES,
  MANUAL_TESTRUN_RESULTS,
  DEVICE_ORIENTATION as DEVICE_ORIENTATION,
  LIST_OF_ASSERTS,
  SUBSCRIPTIONS,
  RESULTS_STATUS_STRING,
  RESULTS_PLAYBACK_STATUS,
};

export const ReleaseMessages = {
  ACCESSIBILITY_RELEASE_MODAL:
    "This application does not have an active release, please create one before performing Accessibility Testing.",
  MANUAL_RELEASE_MODAL:
    "This application does not have an active release, please create one before executing a live or an automated test case.",
  Issue_RELEASE_MODAL:
    "This application does not have an active release, please create one before creating an issue.",
};

export const VARIABLE_TYPE = [
  { title: 'VALUE' ,value: "VALUE"},
  {  title: 'PATTERN', value: "PATTERN"},
  { value: "SECRET", title: 'SECRET' },
];

export const PATTERN_TYPE = [
  { value: "{{ numeric(n) }}", title: "{{ numeric(n) }}" },
  { value: "{{ alphabetic(n) }}", title: "{{ alphabetic(n) }}" },
  { value: "{{ alphanumeric(n) }}", title: "{{ alphanumeric(n) }}" },
  { value: "{{ date(n, DD-MM-YYYY) }}", title: "{{ date(n, DD-MM-YYYY) }}" },
];

export const DATE_FORMAT = [
  { title: "DD-MM-YYYY", value: "DD-MM-YYYY" },
  { title: "M/D/YYYY", value: "M/D/YYYY" },
  { title: "MM/DD/YYYY", value: "MM/DD/YYYY" },
  { title: "MM/DD/YY", value: "MM/DD/YY" },
  { title: "MMM D", value: "MMM D" },
  { title: "MMM DD", value: "MMM DD" },
  { title: "MMM DD, H:MM am/pm", value: "MMM DD, h:mm a" },
  { title: "MMM D, H:MM AM/PM", value: "MMM D, h:mm A" },
  { title: "MMM D at H:MM AM/PM", value: "MMM D [at] h:mm A" },
  { title: "DD/MM/YYYY", value: "DD/MM/YYYY" },
  { title: "DD/MM/YY", value: "DD/MM/YY" },
  { title: "YYYY/MM/DD", value: "YYYY/MM/DD" },
  { title: "YYYY-MM-DD", value: "YYYY-MM-DD" },
  { title: "D Only", value: "D" },
  { title: "DD Only", value: "DD" },
  { title: "MM Only", value: "MM" },
  { title: "Month Only", value: "MMMM" },
  { title: "YYYY Only", value: "YYYY" },
  { title: "Month DD, YYYY", value: "MMMM DD, YYYY" },
  { title: "DD Month YYYY", value: "DD MMMM YYYY" },
  { title: "DD, Month, YYYY", value: "DD, MMMM, YYYY" },
  { title: "D, Month, YYYY", value: "D, MMMM, YYYY" },
  { title: "Day, MMM D", value: "dddd, MMM D" },
];

export const WEEK_DAYS = [
  { title: "Monday", value: 1 },
  { title: "Tuesday", value: 2 },
  { title: "Wednesday", value: 3 },
  { title: "Thursday", value: 4 },
  { title: "Friday", value: 5 },
  { title: "Saturday", value: 6 },
  { title: "Sunday", value: 0 },
]; //value is passed as string through event

export const ASSERT_MAPPING = [
  { code: "18", action: "is present" },
  { code: "29", action: "is not present" },
  { code: "25", action: "is enabled" },
  { code: "26", action: "is disabled" },
  { code: "1", action: "has content" },
  { code: "44", action: "Should be equal" },
  { code: "43", action: "Should not be equal" },
  { code: "22", action: "Should contain" },
  { code: "23", action: "Should not contain" },
  { code: "27", action: "is checked" },
  { code: "28", action: "is unchecked" },
  { code: "45", action: "is equal to" },
  { code: "46", action: "is greater than" },
  { code: "47", action: "is greater than or equal to" },
  { code: "48", action: "is less than" },
  { code: "49", action: "is less than or equal to" },
  { code: "50", action: "is in-between" },
];

export const ASSERT_TUPLES = [
  { code: "18", action: "ASSERT_EXISTS_EXACT_MATCH" },
  { code: "29", action: "ASSERT_NOT_EXISTS_EXACT_MATCH" },
  { code: "19", action: "ASSERT_EQUALS_EXACT_MATCH" },
  { code: "20", action: "ASSERT_NOT_EQUALS_EXACT_MATCH" },
  { code: "21", action: "ASSERT_EXISTS_SIMILAR" },
  { code: "22", action: "ASSERT_EQUALS_SIMILAR" },
  { code: "23", action: "ASSERT_NOT_EQUALS_SIMILAR" },
  { code: "25", action: "ASSERT_ENABLED" },
  { code: "26", action: "ASSERT_NOT_ENABLED" },
  { code: "27", action: "ASSERT_CHECKED" },
  { code: "28", action: "ASSERT_UNCHECKED" },
  { code: "34", action: "ASSERT_TEST_DATA_VARIABLE_EXACT_MATCH" },
  { code: "41", action: "ASSERT_TEST_CASE_VARIABLE" },
  { code: "42", action: "ASSERT_API_STEP_VARIABLE" },
  { code: "43", action: "ASSERT_NOT_EXACT_MATCH" },
  { code: "44", action: "ASSERT_EXACT_MATCH" },
  { code: "45", action: "ASSERT_NUMERIC_EQUAL" },
  { code: "46", action: "ASSERT_NUMERIC_GREATER" },
  { code: "47", action: "ASSERT_NUMERIC_GREATER_EQUAL" },
  { code: "48", action: "ASSERT_NUMERIC_LESSER" },
  { code: "49", action: "ASSERT_NUMERIC_LESSER_EQUAL" },
  { code: "50", action: "ASSERT_NUMERIC_INBETWEEN" },
];

export const STEP_ACTIONS = [
  { icon: 'swipeDown', title: 'Swipe Until', description: 'Automatically perform swipes until selected element is found' },
  { icon: 'assertIcon', title: 'Assert', description: 'Create asserts on any element that allow you to set conditions'},
  //{ icon: 'timeDelay', title: 'Delay', description: 'Create a delay (in ms) before or after the execution of a step' },
  { icon: 'SAVE_TEST_CASE_VARIABLE', title: 'Test Case Variable', description: 'Create a variable that will be used throughout this test case' },
  { icon: 'thresholdIcon', title: 'Threshold', description: 'Set alert thresholds for CPU and memory' },
  { icon: 'APIStepIcon', title: 'API Step', description: 'Define variables from an external API' },
];

export const TIME_DELAY_DATA_TYPES = [
  {
    title: "5 seconds",
    value: "5",
  },
  {
    title: "10 seconds",
    value: "10",
  },
  {
    title: "20 seconds",
    value: "20",
  },
  {
    title: "30 seconds",
    value: "30",
  },
  {
    title: "40 seconds",
    value: "40",
  },
  {
    title: "50 seconds",
    value: "50",
  },
  {
    title: "60 seconds",
    value: "60",
  },
];
