import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { ISubscriptionData } from "../interfaces/SubscriptionData";
import { HttpClient } from "@angular/common/http";
import { map } from "rxjs/operators";
import { SubscriptionEnums } from "../../shared/constants/enums";
import { UtilsService } from "./utils.services";



@Injectable({
  providedIn: "root",
})
export class SubscriptionService {
  UserSubscription$: Observable<ISubscriptionData>;
  subscriptionCode: number;
  subscription;

  liveSubscriptionCodes: string[] = [
    "sofy-live-480-anually",
    "sofy-live-948-annually",
    "sofy-live-49-monthly",
    "sofy-live-99-monthly",
  ];

  automateSubscriptionCodes: string[] = [
    "sofy-automate-1020-anually",
    "sofy-automate-1908-annually",
    "sofy-automate-99-monthly",
    "sofy-automate-199-monthly",
  ];
  httpOptions = {}

  constructor(private http: HttpClient, private utils: UtilsService) {
    this.httpOptions = this.utils.httpOptions
  }
  /**
   * Get subscription data of the user
   * @param UserGuid of session owner
   */
  private getUserSubscription(UserGuid: string) {
    return this.http
      .get<any>(
        environment.baseUrl +
        `/subscription-microservice/user/subscription/${UserGuid}`,
        this.httpOptions
      )
      .pipe(
        map((subDataList) => {
          subDataList = subDataList.data;
          if (this.liveSubscriptionCodes.includes(subDataList.plan_name)) {
            this.subscriptionCode = SubscriptionEnums.LIVE;
          } else if (subDataList.plan_name === "TRIAL") {
            this.subscriptionCode = SubscriptionEnums.TRAIL;
          } else if (
            this.automateSubscriptionCodes.includes(subDataList.plan_name)
          ) {
            this.subscriptionCode = SubscriptionEnums.AUTOMATE;
          } else {
            this.subscriptionCode = 0;
          }
          return subDataList;
        })
      );
  }
  

  Init(UserGuid: string) {
    this.UserSubscription$ = this.getUserSubscription(UserGuid);
    this.UserSubscription$.subscribe((data: any) => {
      this.subscription = data;
    });
    
  }
}
