import { Injectable } from "@angular/core";
import { HttpHeaders } from "@angular/common/http";
import { environment } from "src/environments/environment";
import { gzip } from "pako";
import { BehaviorSubject, Observable, Subject } from "rxjs";
import { LoggingService } from "./logging.service";
import { Constants } from "src/app/shared/constants/constants";
@Injectable({
  providedIn: "root",
})
export class UtilsService {
  httpOptions = {
    headers: new HttpHeaders({
      "Content-Type": "application/json",
      SofyAuth: environment.AUTH_TOKEN,
    }),
  };
  feedbackShown: boolean = false;
  isTutorialRequire: Subject<boolean> = new Subject<boolean>();
  stepActionOverlay: Subject<string> = new Subject<string>();
  assertVariable: Subject<any> = new Subject<any>();
  assertionComponentSelected:  BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  navigationUrl = Constants.NAVIGATION_URL;
  private lastSetLat$ = new BehaviorSubject<number>(47.93879320472554);
  private lastSetLng$ = new BehaviorSubject<number>(-122.15452806030324);

  constructor(private loggingService: LoggingService) { }

  get getLastSetLat$() { return this.lastSetLat$.asObservable(); }
  get getLastSetLng$() { return this.lastSetLng$.asObservable(); }

  appTransformation(app) {
    let format = "url";
    if (app.AppIcon == null) app.AppIcon = "";
    else if (app.AppIcon.startsWith("data:image")) format = "base64";

    return {
      ...app,
      downloadShow: false,
      install: false,
      selected: false,
      loader: false,
      iconFormat: format,
      downloadPercentage: 0,
      selectedBuildIndex: 0,
    };
  }

  isModalOpen(modalId: string) {
    let modalClass = "";
    if (document.getElementById(modalId)) {
      modalClass = document.getElementById(modalId).style.display;
    }
    let checkIsModalClosed =
      modalClass == "none" || modalClass == "" ? true : false;
    return checkIsModalClosed;
  }

  transformPassword(p) {
    if (p) {
      return p.replace(/./g, "*");
    } else {
      return "";
    }
  }

  transformRealTimeLogs() {
    const logPattern = /(.+?)\s+-+\s+\((\d{4}-\d{2}-\d{2} \d{2}:\d{2}:\d{2})\)/;
    const logHistory = this.loggingService
      .getLogHistory()
      .map((logEntry: string) => {
        const match = logEntry.match(logPattern);
        if (match && match.length === 3) {
          const logMessage = match[1].trim();
          const timestamp = match[2];
          return `${"("}${timestamp}${")"} ${logMessage}`;
        }
        return logEntry; // If the log entry doesn't match the pattern, keep it as is
      })
      .join("\n");
    return logHistory;
  }
  normalizeBounds(bounds: any): any {
    return this.parseBounds(bounds)
      .split(",")
      .map((n) => parseInt(n, 10)) // here divided by two was written
      .map((n) => Math.ceil(n))
      .join(",");
  }
  parseBounds(bounds: any): string {
    if (!bounds && typeof bounds !== "string") {
      return "";
    }
    bounds = bounds.replaceAll(" ", "");
    bounds = bounds.replace("][", ",");
    bounds = bounds.replace("[", "");
    bounds = bounds.replace("]", "");
    return bounds;
  }
  calculateSrcBounds(bounds: any, imageWidth = 1, deviceWidth = 1): any {
    const imageDeviceRatio =
      imageWidth > deviceWidth
        ? imageWidth / deviceWidth
        : deviceWidth / imageWidth;
    const isMultiply = imageWidth > deviceWidth ? true : false;
    // && imageDeviceRatio < 1.3
    // .map(n => parseInt(n, 10) / (imageDeviceRatio * 1.95))
    if (isMultiply) {
      return bounds
        .split(",")
        .map((n) => parseInt(n, 10) * imageDeviceRatio)
        .map((n) => Math.ceil(n))
        .join(",");
    } else {
      return bounds
        .split(",")
        .map((n) => parseInt(n, 10) / imageDeviceRatio)
        .map((n) => Math.ceil(n))
        .join(",");
    }
  }

  getImageDimension(image: any): Observable<any> {
    return new Observable((observer) => {
      const img = new Image();
      img.onload = (event) => {
        const loadedImage: any = event.currentTarget;
        image.width = loadedImage.width;
        image.height = loadedImage.height;
        observer.next(image);
        observer.complete();
      };
      img.src = image.url;
    });
  }

  async compress(data: any): Promise<any> {
    try {
      const jsonString = JSON.stringify(data);
      const jsonUint8Array = new TextEncoder().encode(jsonString);
      const compressedData = gzip(jsonUint8Array, { level: 9 });
      //converting the compressed data to string for better file upload.
      let convertedString = "";
      for (let i = 0; i < compressedData.length; i++) {
        convertedString += String.fromCharCode(compressedData[i]);
      }
      return convertedString;
    } catch (error) {
      console.log("error compressing!!!");
    }
  }

  // Navigate to Accessibility, Insert Step and Results
  navigateToPortal(path: string) {
    let baseUrl;
    const hostname = window.location.hostname;
    const localHostUrl = this.navigationUrl.LOCALHOST;
    const labUrl = this.navigationUrl.PROD;
    const preProdUrl = this.navigationUrl.PRE_PROD;
    const devTestUrl = this.navigationUrl.DEV_TEST;
    switch (hostname) {
      case "localhost":
        baseUrl = localHostUrl;
        break;
      case "lab.sofy.ai":
        baseUrl = labUrl;
        break;
      case "lab-sofy-stag-01-pre-prod.azurewebsites.net":
        baseUrl = preProdUrl;
        window.open(baseUrl + path, "_blank");
        return;
      default:
        baseUrl = devTestUrl;
    }
    window.open(baseUrl + path, "_blank");
  }

  /* Matches - {lat} | {lng} - that comes in the data message then extracts them to
  update the last set latitude and longitude */
  
  updateLatLng(data: string) {
    const extractedCoordinates = data.match(/(-?\d+\.\d+)\s*\|\s*(-?\d+\.\d+)/);
    if (extractedCoordinates) {
      const latitude = parseFloat(extractedCoordinates[1]);
      const longitude = parseFloat(extractedCoordinates[2]);
      this.lastSetLat$.next(Number(latitude));
      this.lastSetLng$.next(Number(longitude));
    }
  }
}
