import { Injectable, Injector, NgZone } from "@angular/core";
import { interval, Observable, Subject, Subscription, noop } from "rxjs";
import { tap } from "rxjs/operators";
import { HttpClient } from "@angular/common/http";
import { environment } from "src/environments/environment";
import { ToasterService } from "./toaster.service";
import { PlayBackService } from "./playback.service";
import { SessionSocketService } from "./session-socket.service";
import { SofyLiveService } from "./sofylive.service";

import { UtilsService } from "./utils.services";
import {
  MANUAL_TESTRUN_RESULTS,
  TIMEREVENTS,
} from "../../shared/constants/constants";
import { ActivatedRoute } from "@angular/router";
import { OverlayService } from "./overlay.service";

@Injectable({
  providedIn: "root",
})
export class TimerService {
  hours: number;
  minutes: number;
  seconds: number;
  testId: any;
  timer$: Observable<number>;
  timerSubscription: Subscription;
  timerEvents$: Subject<string> = new Subject<string>();
  httpOptions = {};
  userGUID: any;
  userId: any;
  isSessionEnded: boolean = false;
  parentGuid: string;

  constructor(
    private httpClient: HttpClient,
    private injector: Injector,
    private sofyLive: SofyLiveService,
    private sessionSocket: SessionSocketService,
    private utils: UtilsService,
    private route: ActivatedRoute,
    private overlayService: OverlayService,
  ) {
    this.sofyLive.issueCreateModal_ModelData.subscribe((result) => {
      this.testId = result ? result.TestCaseResultId : null;
    });
    this.httpOptions = this.utils.httpOptions;
  }
  setSeconds(_seconds: number) {
    if (_seconds > 59) {
      throw new Error("Invalid Seconds");
    }
    this.seconds = _seconds;
  }

  setMinutes(_minutes: number) {
    if (_minutes > 59) {
      throw new Error("Invalid Minutes");
    }
    this.minutes = _minutes;
  }

  setHours(_hours: number) {
    this.hours = _hours;
  }

  startTimer() {
    if (this.timerSubscription) {
      this.timerSubscription.unsubscribe();
    }

    this.timer$ = interval(1000).pipe(
      tap(() => {
        this.handleTick();
      })
    );

    this.timerSubscription = this.timer$.subscribe();
    this.timerEvents$.next(TIMEREVENTS.STARTED_SESSION);
  }

  private handleTick() {
    if (this.seconds === 0) {
      if (this.minutes === 0) {
        if (this.hours === 0) {
          this.handleTimerComplete();
        } else {
          this.decrementHour();
        }
      } else {
        this.decrementMinute();
      }
    } else {
      this.seconds -= 1;
    }
  }

  private decrementHour() {
    this.hours -= 1;
    this.minutes = 59;
    this.seconds = 59;
  }

  private decrementMinute() {
    this.minutes -= 1;
    this.seconds = 59;
  }

  private handleTimerComplete() {
    let playbackService = this.injector.get(PlayBackService);
    let sessionSocketService = this.injector.get(SessionSocketService);

    playbackService.ForceStopAllPlayBacks();
    sessionSocketService.SendForceStopPlaybacks();

    if (this.testId != null) {
      this.sessionSocket.SendManualEnd(
        this.testId,
        MANUAL_TESTRUN_RESULTS.CANCELLED
      );
    }

    this.timerEvents$.next(TIMEREVENTS.COMPLETED_SESSION);
    this.isSessionEnded = true;
    this.overlayService.SetOverlayText("Session Expired");
    this.overlayService.HideLoading();
    this.overlayService.ShowOverlay();
  }

  ResetTimer() {
    if (this.timerSubscription) {
      this.timerSubscription.unsubscribe();
    }
    this.hours = 0;
    this.minutes = 0;
    this.seconds = 0;
    this.timerEvents$.next(TIMEREVENTS.RESET_SESSION);
  }

  Init(SessionGUID: string): void {
    this.GetSessionRemainingTime(SessionGUID).subscribe(
      (Response) => {
        if (Response.data.Expired) {
          if (this.testId != null)
            this.sessionSocket.SendManualEnd(
              this.testId,
              MANUAL_TESTRUN_RESULTS.CANCELLED
            );
          const plS = this.injector.get(PlayBackService);
          const ssS = this.injector.get(SessionSocketService);
          plS.ForceStopAllPlayBacks();
          ssS.SendForceStopPlaybacks();
          this.timerEvents$.next(TIMEREVENTS.ENDED_SESSION);
        }

        this.setHours(this.CalculateTimeData(Response.data.RemainingTime, 1));
        this.setMinutes(this.CalculateTimeData(Response.data.RemainingTime, 2));
        this.setSeconds(this.CalculateTimeData(Response.data.RemainingTime, 3));
      },
      (_err: any) => noop,
      () => this.startTimer()
    );
  }

  CalculateTimeData(RemainingTime: number, flag: number): any {
    switch (flag) {
      case 1:
        return Math.floor(RemainingTime / 3600);
      case 2:
        return Math.floor((RemainingTime % 3600) / 60);
      case 3:
        return RemainingTime % 60;
    }
  }

  ExtendSession(SessionGUID: string): void {
    this.route.queryParams.subscribe((params) => {
      this.userId = params.userID;
      this.userGUID = params.UserGUID;
      this.parentGuid = params.parentGuid;
    });
    const body = {
      userGuid:
        this.parentGuid == this.userGUID
          ? this.userGUID
          : this.parentGuid
    };
    this.ExtendIDESession(SessionGUID, body).subscribe(
      (res) => {
        this.timerSubscription.unsubscribe();
        this.timer$ = null;

        this.setHours(0);
        this.setMinutes(0);
        this.setSeconds(0);
        setTimeout(() => {
          const h = this.CalculateTimeData(res.data.remainingTime, 1);
          const m = this.CalculateTimeData(res.data.remainingTime, 2);
          const s = this.CalculateTimeData(res.data.remainingTime, 3);
          this.setHours(h);
          this.setMinutes(m);
          this.setSeconds(s);
          this.startTimer();
        }, 10);
      },
      (error) => {
        if (error.error.message === "Device Hour Limit Exceeded") {
          const toasterService = this.injector.get(ToasterService);
          toasterService.ShowToastMessage(
            "Device Hour Limit Exceeded!",
            0,
            2000
          );
        }
      }
    );
  }

  private ExtendIDESession(sessionGUID: string, body: any): Observable<any> {
    return this.httpClient.put<any>(
      `${environment.baseUrl}/session-microservice/session/${sessionGUID}/extend`,
      body,
      this.httpOptions
    );
  }

  GetSessionRemainingTime(sessionGUID: string): Observable<any> {
    return this.httpClient.get<any>(
      `${environment.baseUrl}/session-microservice/session/${sessionGUID}/time`,
      this.httpOptions
    );
  }
}
